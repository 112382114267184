import { format } from "date-fns";
import { enGB, es, fr, pt } from "date-fns/locale";
import {
  BRAND_COLORS,
  FORMS_ORIGIN,
  IS_DEVELOPMENT,
  PUBLIC_ROUTES,
  ROUTES,
  SEARCH_RESULT_TYPES,
  SISTEMAS_Y_CONTROL_ID,
  RMA_STATUS,
  ORDERS_STATUS,
  BUDGETS_STATUS,
} from "./global";

import { BsBookmark, BsCheck2, BsGear, BsHandThumbsUp } from "react-icons/bs";

/**
 * @description Alterna la clase de un elemento.
 * @param {Boolean} isResponsiveMenu Determina si el evento viene desde el mené responsive o desde el propio nav.
 */
export const handleHtmlClass = (isResponsiveMenu) => {
  const html = document.querySelector("html");

  if (isResponsiveMenu) {
    html.classList.remove("sidebar-left-collapsed");
    html.classList.toggle("sidebar-left-opened");
  }

  html.classList.remove("sidebar-left-opened");
  html.classList.toggle("sidebar-left-collapsed");
};

/**
 * @description Alterna (añade o elimina) un ítem de un array.
 * @param {Array} array Colección.
 * @param {any} item Item a alternar.
 * @returns {Array}
 */
export const toggleArrayElement = (array, item) => {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  }

  return [...array, item];
};

/**
 * @description Comprueba si una variable es un objeto y si éste está vacío.
 * @param {Object} obj Objecto a checkear.
 * @returns {Boolean}
 */
export const isEmptyObject = (obj) => {
  /**
   * Descarta los valaores Falsy:
   *
   * - false
   * - undefined
   * - null
   * - NaN
   * - 0
   * - ""
   */
  if (!Boolean(obj)) {
    return false;
  }
  // No es un objeto.
  if (Object.getPrototypeOf(obj) !== Object.prototype) {
    return false;
  }

  // El objeto tiene valores.
  if (Object.keys(obj).length !== 0) {
    return false;
  }

  return true;
};

/**
 * @description Determina si una variable es un number.
 * @param {any} value Valor a comprobar.
 * @returns {boolean}
 */
export const isNumber = (value) =>
  typeof (value === "number" || value instanceof Number) && isFinite(value);

/**
 * @description Determina si una variable es un string.
 * @param {any} value Valor a comprobar.
 * @returns {boolean}
 */
export const isString = (value) =>
  typeof value === "string" || value instanceof String;

/**
 * @description Retorna los valores de un array u objeto.
 * @param {Object|Array} arrayOrObject
 * @returns {Array}
 */
export const getArrayOrObjectValues = (arrayOrObject) => {
  if (!arrayOrObject) {
    return [];
  }

  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject;
  }

  if (isEmptyObject(arrayOrObject)) {
    return [];
  }

  return Object.values(arrayOrObject);
};

/**
 * @description Separa un texto por párrafos.
 * @param {string} text Texto a mapear. Cada salto de línea se transforma a <p>.
 * @returns {array|string}
 */
export const breakLineToBr = (text) => {
  return text ? text.split("\n").map((str, idx) => <p key={idx}>{str}</p>) : "";
};

/**
 * @description Construye la configuración para las peticiones fetch.
 * @param {Object} defaultConfig Configuración inicial.
 * @param {Object} customHeaders Cabeceras que añadir o sobreescribir a la configuración inicial.
 * @returns {Object}
 */
export const constructInitRequestConfig = (
  defaultConfig,
  customHeaders,
  json = {},
) => {
  const config = {
    ...defaultConfig,
    ...{ headers: { ...defaultConfig.headers, ...customHeaders } },
  };

  if (
    !["POST", "PUT", "PATCH", "DELETE"].includes(config.method.toUpperCase())
  ) {
    return config;
  }

  /**
   * @description Realiza un trim() en todos los campos de tipo string, si es undefined envía null en caso contrario el mismo valor.
   * @param {string} key Clave.
   * @param {any} value Valor.
   * @returns {any}
   */

  const trimStringValues = (key, value) => {
    return typeof value === "string"
      ? value.trim()
      : value === undefined
        ? null
        : value;
  };

  return {
    ...config,
    body: JSON.stringify(json, trimStringValues),
  };
};

/**
 * @description Muestra un mensaje dependiendo del entorno.
 * @param {string} message Mensaje a mostrar si está en modo desarrollo.
 * @param {string} defaultMessage Mensaje a mostrar si no está en modo desarrollo.
 * @returns {string}
 */
export const debugMessage = (message, defaultMessage = "") => {
  return IS_DEVELOPMENT ? message : defaultMessage;
};

/**
 * @description SWR en su versión 2 ya no acepta argumentos múltiples en el fetcher. Esta función se ha creado para la
 * compatibilidad.
 * @see https://swr.vercel.app/blog/swr-v2#fetcher-no-longer-accepts-multiple-arguments
 */
export const defaultFetcherSwr = async ([resource, init = {}]) => {
  return await defaultFetcher(resource, init);
};

/**
 * @description Fetch por defecto.
 * @param {string} resource Endpoint al que hay que llamar.
 * @param {Object} init Configuración para el fetch.
 * @returns
 */
export const defaultFetcher = async (resource, init = {}) => {
  const response = await fetch(resource, init);

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await response.json();

    if (
      error.info["status-details"] ===
      "El servidor se encuentra en mantenimiento"
    ) {
      window.location.href =
        "https://clientes.fonestar.com/" + PUBLIC_ROUTES.es.maintenance;
    }
    /* if (error.info._links.self.href === "/rmas") {
      localStorage.setItem("error", true);
      localStorage.setItem("status-details", error.info["status-details"]);

      window.location.href =
        window.location.origin + "/rmas/" + ROUTES.es.rmas.estado;
    } */

    error.status = response.status;

    throw error;
  }

  return response.json();
};

/**
 * @description Fetch para subida de archivos y facturas.
 * @param {string} url Endpoint para hacer el Fetch.
 * @param {json} json Datos de formulario tipo JSON.
 * @param {object} files Factura adjunta.
 * @returns
 */

export const fileFetcher = async (url, json, files) => {
  const formData = new FormData();

  formData.append("json", json.body);
  formData.append("files", files);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data",
      "X-API-KEY": process.env.REACT_APP_X_API_KEY,
      fstoken: `${json.headers.fstoken}`,
      lng: `${json.headers.lng}`,
      // debug: IS_DEVELOPMENT ? 1 : 0,
    },
    body: formData,
  });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await response.json();

    if (
      error.info["status-details"] ===
      "El servidor se encuentra en mantenimiento"
    ) {
      window.location.href(
        window.location.origin + "/" + PUBLIC_ROUTES.es.maintenance,
      );
    }

    error.status = response.status;

    throw error;
  }

  return response.json();
};

/**
 * @description Fetch para realizar pedidos en el nuevo formato API.
 *
 */

export const orderFetcher = async (url, json) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-API-KEY": process.env.REACT_APP_X_API_KEY,
      "Content-Type": "application/json",
      fstoken: `${json.headers.fstoken}`,
      lng: `${json.headers.lng}`,
      // debug: IS_DEVELOPMENT ? 1 : 0,
    },
    body: `${json.body}`,
  });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await response.json();

    if (
      error.info["status-details"] ===
      "El servidor se encuentra en mantenimiento"
    ) {
      window.location.href(
        window.location.origin + "/" + PUBLIC_ROUTES.es.maintenance,
      );
    }

    error.status = response.status;

    throw error;
  }

  return response.json();
};

/**
 * @description Fetch para realizar la actualización de las notificaciones.
 *
 */

export const notificationFetcher = async (url, json) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-API-KEY": process.env.REACT_APP_X_API_KEY,
      "Content-Type": "application/json",
      fstoken: `${json.headers.fstoken}`,
      lng: `${json.headers.lng}`,
      // debug: IS_DEVELOPMENT ? 1 : 0,
    },
    body: `${json.body}`,
  });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await response.json();

    if (
      error.info["status-details"] ===
      "El servidor se encuentra en mantenimiento"
    ) {
      window.location.href(
        window.location.origin + "/" + PUBLIC_ROUTES.es.maintenance,
      );
    }

    error.status = response.status;

    throw error;
  }

  return response.json();
};

/**
 * @description Devuelve las iniciales de un nombre completo.
 * @param {string} name Nombre del que sacar las iniciales.
 * @returns {string}
 * @example "Ángel" => "ÁN"
 * @example "Ángel Guerra" => "ÁG"
 * @example "Ángel Luis Guerra" => "ÁG"
 */
export const getInitials = (name) => {
  return name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

/**
 * @description Genera un canvas a partir de un nombre con sus iniciales.
 * @param {number} size Tamaño de la imagen.
 * @param {string} name Nombre del que inferir las iniciales.
 * @param {string} bgColor Color de fondo de la imagen.
 * @param {string} textColor Color de las iniciales.
 * @returns {string}
 */
export const createImageFromInitials = (
  size,
  name,
  bgColor = BRAND_COLORS.red,
  textColor = "#ffffff",
) => {
  const initials = getInitials(name);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = bgColor;
  context.fillRect(0, 0, size, size);

  context.fillStyle = textColor;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `bold ${size / 3}px Poppins`;
  context.fillText(initials, size / 2, size / 1.8);

  return canvas.toDataURL();
};

/**
 * @description Genera un número aleatorio entero entre dos dados.
 * @param {Number} max Número máximo a generar.
 * @param {Number} min Número mínimo a generar.
 * @returns {Number}
 */
export const generateRandomInt = (max, min = 0) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * @description Fallback para cuando ocurre un error al cargar una imagen.
 * @param {Event} event Evento disparado (onError).
 * @param {boolean} hide Determina si hay que ocultar la imagen.
 * @param {string} replaceUrl Nuevo src a cargar.
 */
export const imgOnErrorFallback = (event, hide = false, replaceUrl = "") => {
  const currentTarget = event.currentTarget;

  currentTarget.onerror = null;
  currentTarget.src = replaceUrl;

  if (hide) {
    currentTarget.style.display = "none";
  }
};

/**
 * @description Casi todas las respuestas vienen dentro de un campo _items que es un array, incluso si se está accediendo a una vista,
 * esta función extrae el resultado de ese campo.
 * @param {object} parent Objeto del que extraer el campo.
 * @param {boolean} expectArrayChildren Determina si llega como `_items: [{}]` o como `_items: {}`, por defecto se espera que sea array.
 * @param {string} key Clave a extraer del objeto.
 * @returns {any}
 */
export const extractItemsFromApiRequest = (
  parent,
  expectArrayChildren = true,
  key = "_items",
) => {
  if (!parent) {
    return [];
  }

  if (!parent[key]) {
    return [];
  }

  if (expectArrayChildren) {
    return parent[key][0] ?? [];
  }

  return parent[key] ?? [];
};

/**
 * @description Devuelve la clave del fichero de traducciones del lugar recomendado a través del código que retorna la API.
 * @param {string} code Código retornado por la API.
 * @returns {string}
 */
export const getPlaceI18nKeyByCode = (code) => {
  switch (code) {
    case "0N_ES":
      return "todos_los_sectores";
    case "10FA":
      return "fabricas";
    case "TO2":
      return "todos_los_sectores";
    case "1CC":
      return "centros_de_culto";
    case "2HO":
      return "hosteleria";
    case "3RE":
      return "retail";
    case "4ID":
      return "instalaciones_deportivas";
    case "5ED":
      return "educacion";
    case "6HO":
      return "hoteles";
    case "7ET":
      return "estaciones_de_transporte";
    case "8CS":
      return "centros_sociosanitarios";
    case "9OF":
      return "oficinas";
    case "11TU":
      return "instalaciones_turisticas";
    case "12IN":
      return "institucional";
    default:
      return "todos_los_sectores";
  }
};

/**
 * @description La categoría de "Sistemas y Control" es especial. Se utiliza, por ejemplo, con la función `filter`.
 * @param {object} category Categoría.
 * @returns {boolean}
 */
export const isSistemasYControlCategory = (category) =>
  parseInt(category.ID) === SISTEMAS_Y_CONTROL_ID;

/**
 * @description Alias para la negación de la función superior.
 * @param {object} category Categoría.
 * @returns {boolean}
 */
export const isNotSistemasYControlCategory = (category) =>
  !isSistemasYControlCategory(category);

/**
 * @description Convierte una cadena separada por un separador en un array.
 * @param {string} str Cadena a convertir.
 * @param {string} separator Separador que utiliza.
 * @param {CallableFunction} callback Función a ejecutar en cada resultado.
 * @example
 * ```js
 * commaSeparatedStringToArray("120,121"); // ["120", "121"]
 * commaSeparatedStringToArray("120, 121"); // ["120", "121"]
 * commaSeparatedStringToArray("120-121", "-"); // ["120", "121"]
 * commaSeparatedStringToArray("120,121", ",", (e) => parseInt(e)); // [120, 121]
 * ```
 * @returns {array}
 */
export const commaSeparatedStringToArray = (
  str,
  separator = ",",
  callback = (e) => e,
) => {
  if (!str) {
    return [];
  }

  return str
    .split(separator)
    .map((element) => element.trim())
    .filter((element) => element !== "")
    .map(callback);
};

/**
 * @description Une un array en una cadena de caracteres
 * @param {array} array Array a unir.
 * @param {string} separator Separador a utilizar.
 * @example
 * ```js
 * arrayToCommaSeparatedString([120,121]); // "120,121"
 * arrayToCommaSeparatedString([120,121], " - "); // "120 - 121"
 * ```
 * @returns {string}
 */
export const arrayToCommaSeparatedString = (array, separator = ",") => {
  if (!array.length) {
    return "";
  }

  return array.join(separator);
};

/**
 * @description Escapa los caracteres especiales para que la búsqueda en el lado del servidor funcione correctamente.
 * - !
 * - "
 * - $
 * - '
 * - (
 * - )
 * - -
 * - /
 * - <
 * - @
 * - \
 * - ^
 * - |
 * - ~
 * @param {string} query Query a escapar.
 * @returns {string}
 */
export const escapeSearchQuery = (query) => {
  if (!query) {
    return "";
  }

  return query.replace(new RegExp(/(?=[!"$'()\-/<@\\^|~])/, "gm"), "\\");
};

/**
 * @description Envía los datos del formulario a un Contact Form de WordPress.
 * @param {string} language Idioma en el que se envía el formulario.
 * @param {int} wpFormId Identificador del Contact Form de WordPress.
 * @param {object} data Datos recogidos del formulario.
 * @returns {Promise}
 */
export async function sendFormDataToWordPress(
  language,
  id_formulario,
  data,
  token,
) {
  const body = { language: language, origen: FORMS_ORIGIN, ...data };
  const formData = new FormData();

  for (const bodyKey in body) {
    formData.append(bodyKey, body[bodyKey]);
  }

  return defaultFetcher(
    `${process.env.REACT_APP_API_BASE_URL}/v1/forms/${id_formulario}`,
    {
      method: "POST",
      credentials: "omit",
      body: formData,
      headers: {
        "X-API-KEY": process.env.REACT_APP_X_API_KEY,
        fstoken: token,
        Accept: "application/json",
      },
    },
  );
}

/**
 * @description Convierte el enlace de la API a un enlace del área de cliente.
 * @param {object} result Resultado devuelto por la API.
 * @param {string} locale Idioma seleccionado.
 * @example `es/ANG-5L -> productos/ang-5l`
 * @returns
 */
export const getHrefFromSearchEndpointResult = (result, locale) => {
  return (
    "/" +
    result._source.slug
      .replace(
        /^(?:es|en|fr|pt)/i,
        ROUTES[locale][
          result._source.documento.toLowerCase() === "producto"
            ? "products"
            : "categories"
        ],
      )
      .toLowerCase()
  );
};

/**
 * @description Sube el scroll hasta alinear arriba el contenido principal.
 * @returns {void}
 */
export const scrollToMainContent = () => {
  document.querySelector(".content-body[role='main']").scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

/**
 * @description Formatea la fecha con date-fns.
 * @param {string|Date} date Fecha a formatear.
 * @param {string} f Formato a aplicar.
 * @param {string} locale Internacionalización a aplicar.
 * @returns {string}
 */
export const formatDate = (date, f, locale) => {
  try {
    if (date === undefined) {
      return "-";
    }

    if (date === "01.01.1970") {
      return "-";
    }
    const formatDate = format(date instanceof Date ? date : new Date(date), f, {
      locale: getI18nDateFnsLocale(locale),
    });

    if (formatDate === "01.01.1970") {
      return "-";
    } else {
      return formatDate;
    }
  } catch {
    return date;
  }
};

/**
 * @description Carga el locale correspondiente para date-fns.
 * @param {string} locale Internacionalización a aplicar.
 * @returns {Locale}
 */
export const getI18nDateFnsLocale = (locale) => {
  if (locale === "fr") {
    return fr;
  }

  if (locale === "en") {
    return enGB;
  }

  if (locale === "pt") {
    return pt;
  }

  return es;
};

/**
 * @description Calcula la diferencia en días de entre una fecha y el día actual.
 * @param {Date} date Fecha.
 * @returns {Number} días de diferencia entre las fechas.
 */
export const getDateDiffInDays = (date) => {
  let today = new Date().getTime();
  let endDate = new Date(date).getTime();

  return parseInt((endDate - today) / (1000 * 60 * 60 * 24) + 1);
};

/**
 * @description Ejecuta una función o bien retorna `undefined`.
 * @param {bool} returnCallback Determina si hay que ejecutar la función o retornar `undefined`.
 * @param {CallableFunction} callback Función a ejecutar.
 * @returns {undefined|CallableFunction}
 */
export const runCallbackOrGetUndefined = (returnCallback, callback) => {
  return returnCallback ? callback() : undefined;
};

/**
 * @description Determina el tipo de resultado del buscador.
 * @param {object} source Clave _source del resultado del buscador.
 * @returns {string}
 */
export const getSearchResultType = (source) => {
  if (source.tipo_resultado?.toLowerCase() === "resultado_contenidos") {
    return SEARCH_RESULT_TYPES.content;
  }

  if (source.tipo_documento?.toLowerCase() === "producto") {
    return SEARCH_RESULT_TYPES.product;
  }

  return SEARCH_RESULT_TYPES.category;
};

/**
 * @description Devuelve el color según el estado de un RMA.
 * @param {Number} id Id del estado del RMA.
 * @param {Object}
 */
export const getColorByRMAstate = (id) => {
  switch (id) {
    case RMA_STATUS.onHold:
      return { text: "success", icon: BsHandThumbsUp };
    case RMA_STATUS.inProgress:
      return { text: "warning", icon: BsGear };
    case RMA_STATUS.completed:
      return { text: "dark", icon: BsBookmark };
    case RMA_STATUS.saved:
      return { text: "danger", icon: BsCheck2 };
    default:
      return { text: "warning", icon: BsCheck2 };
  }
};

/**
 * @description Obtiene el string a concatenar a una clase para los estilos de un pedido según su estado en el listado de pedidos. También devuelve el icono del timeline según el estado del pedido.
 * @param {Number} id Id del estado.
 * @param {Object}
 */
export const getColorAndIconTimelineForOrderState = (id) => {
  switch (id) {
    case ORDERS_STATUS.inProgress:
      return { text: "success", icon: BsHandThumbsUp };
    case ORDERS_STATUS.onHold:
      return { text: "warning", icon: BsGear };
    case ORDERS_STATUS.send:
      return { text: "dark", icon: BsBookmark };
    case ORDERS_STATUS.completed:
      return { text: "danger", icon: BsCheck2 };
    default:
      return { text: "warning", icon: BsCheck2 };
  }
};

/**
 * @description Obtiene el string a concatenar a una clase para los estilos de un presupuesto según su estado en el listado de presupuestos.
 * También devuelve el icono del timeline según el estado del presupuesto.
 * @param {Number} id Id del estado.
 * @param {Object}
 */
export const getColorAndIconTimelineForBudgetState = (id) => {
  switch (id) {
    case BUDGETS_STATUS.valid:
      return { text: "success", icon: BsHandThumbsUp };
    case BUDGETS_STATUS.expiredOrCancelled:
      return { text: "warning", icon: BsGear };
    case BUDGETS_STATUS.accepted:
      return { text: "dark", icon: BsBookmark };
    case BUDGETS_STATUS.onHold:
      return { text: "danger", icon: BsCheck2 };
    default:
      return { text: "warning", icon: BsCheck2 };
  }
};

/**
 * @description Obtiene el string a concatenar a una clase para los estilos del pedido o cotización según su estado.
 * @param {Number} id Id del estado.
 * @param {Object}
 */
export const getIconFromOrderState = (id) => {
  switch (id) {
    case ORDERS_STATUS.onHold:
      return BsHandThumbsUp;
    case ORDERS_STATUS.inProgress:
      return BsHandThumbsUp;
    case ORDERS_STATUS.send:
      return BsHandThumbsUp;
    case ORDERS_STATUS.completed:
      return BsHandThumbsUp;
    default:
      return BsGear;
  }
};

/**
 * @description Obtiene el string de la descripción de un pedido en base a su estado.
 * @param {Number} id Id del estado.
 * @param {string}
 */
export const getDescriptionFromOrderState = (id) => {
  switch (id) {
    case ORDERS_STATUS.onHold:
      return "revisa_tu_email";
    case ORDERS_STATUS.inProgress:
      return "orden_recibida_preparando_el_pedido_salida_de_almacen_en_24_48h";
    case ORDERS_STATUS.send:
      return "consulta_tu_informacion_en_tu_email_de_la_empresa_de_transporte";
    case ORDERS_STATUS.completed:
      return "";
    default:
      return "";
  }
};

/**
 * @description Obtiene el string a de información de un pedido en base a su estado estado.
 * @param {Number} id Id del estado.
 * @param {string}
 */
export const getInformationStateFromOrderState = (id) => {
  switch (id) {
    case ORDERS_STATUS.onHold:
      return "email_confirmacion";
    case ORDERS_STATUS.inProgress:
      return "pedido_preparado";
    case ORDERS_STATUS.send:
      return "pedido_en_entrega";
    case ORDERS_STATUS.completed:
      return "pedido_entregado";
    default:
      return "";
  }
};

/**
 * @description Determina si una ruta pertenece a las públicas.
 * @param {string} location Ruta a comprobar.
 * @returns {Boolean}
 */
export const isPublicRoute = (location) =>
  new Set(
    Object.values(PUBLIC_ROUTES).flatMap((lang) => Object.values(lang)),
  ).has(location);

/**
 * @description Determina si es una ruta que al cambiar de idioma da error y hay que redirigir a otro sitio.
 * @param {string} pathname Atributo sacado de location.
 * @returns {boolean}
 */
export const mustResetRoute = (pathname) => {
  return Object.keys(ROUTES)
    .map((lang) => ROUTES[lang].categories)
    .some((route) => pathname.includes(route));
};

/**
 * @description Recibe un objeto response de la petición a la API y crea la cadena de texto apropiada
 * Devuelve el array de líneas validadas
 * @param {response} response
 * @returns {array}
 */
export const errorResetPwdMsg = (response) => {
  let msg = "";
  if (response !== undefined && response["status-passset_pass"] !== undefined) {
    var err = response["status-passset_pass"].errors;
    if (err) {
      msg =
        (!err.long ? "resetpwd_long\n" : "") + // Demasiado corta, al menos 8 caracteres
        (!err.uppercase ? "resetpwd_uppercase\n" : "") + // Necesita al menos una mayúscula
        (!err.letters ? "resetpwd_letter\n" : "") + // Necesita al menos un letra
        (!err.numbers ? "resetpwd_number\n" : "") + // Necesita al menos un número
        (!err.specialcharts ? "resetpwd_specialchar\n" : ""); //Necesita al menos un símbolo
    }
  }

  // Convertimos en array y lo devolvemos
  return msg.split("\n").filter((element) => element);
};

/**
 * @description Comprueba que una variable sea un objeto y que NO esté vacío.
 * @param {Object} variable Variable que se quiere comprobar.
 * @returns {boolean} Devuelve true si es un objeto y éste no está vacío.
 */
export const isObjectWithData = (variable) => {
  return (
    variable !== undefined &&
    variable !== null &&
    typeof variable === "object" &&
    Object.keys(variable).length > 0
  );
};
